@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gothamrnd_book.otf') format('truetype');
}
@font-face {
  font-family: 'ProductSans';
  src: url('./fonts/productsans_regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProductSansBold';
  src: url('./fonts/productsans_bold.ttf') format('truetype');
}

/* Optionally, define font styles using the imported font */
body {
  font-family: 'ProductSans', sans-serif;
}


@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}